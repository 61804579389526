import { getApolloContext } from "@apollo/client";
import gql from "graphql-tag";
import React, { useState, useEffect, useContext } from "react";

const UPDATE_HOME_KIT = gql`
  mutation UpdateKit($id: String!, $kit: String!) {
    updateGame(id: $id, home_kit: $kit) {
      home_team_kit
    }
  }
`;

const UPDATE_AWAY_KIT = gql`
  mutation UpdateKit($id: String!, $kit: String!) {
    updateGame(id: $id, away_kit: $kit) {
      away_team_kit
    }
  }
`;

const COMPETITIONS = gql`
  query Competitions {
    competitions {
      _id
      name
      description
      opta_ID
      formation
      code
      country
      country_code
      competition_type
      badge
    }
  }
`;

const GAMES = gql`
  query Games {
    games {
      _id
      time
      description
      date
      competition {
        _id
        name
        badge
        competition_type
        colour
      }
      home_team {
        _id
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        name
        short_name
        code
        variables
      }
      live_data
      opta_team_stats
      opta_player_stats
      config
    }
  }
`;

const GAMES_BY_DATE = gql`
  query Games($date: String) {
    gamesByDate(date: $date) {
      _id
      time
      description
      date
      stadium {
        opta_name
        name
      }
      competition {
        _id
        name
        badge
        competition_type
        colour
      }
      lineup {
        home {
          squad {
            _id
            shirt_number
            opta_shirt_number
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              opta_first_name
              opta_last_name
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
        }
        away {
          squad {
            _id
            shirt_number
            opta_shirt_number
            player {
              _id
              first_name
              last_name
              first_name_upper
              last_name_upper
              opta_first_name
              opta_last_name
              nationality {
                _id
                opta_code
                opta_name
              }
            }
          }
          order
          yellow_card
          dbl_yellow_card
          red_card
        }
      }
      opta_lineup {
        home {
          squad {
            _id
            opta_shirt_number
            player {
              first_name
              last_name
              opta_first_name
              opta_last_name
            }
          }
          order
        }
        away {
          squad {
            _id
            opta_shirt_number
            player {
              first_name
              last_name
              opta_first_name
              opta_last_name
            }
          }
          order
        }
      }
      home_team {
        _id
        name
        short_name
        code
        variables
      }
      away_team {
        _id
        name
        short_name
        code
        variables
      }
      live_data
      opta_team_stats
      opta_player_stats
      config
      home_scorers
      away_scorers
    }
  }
`;

const UPDATE_GAME = gql`
  mutation UpdateGame(
    $id: String!
    $live_data: JSON
    $lineup: JSON
    $home_scorers: JSON
    $away_scorers: JSON
    $home_penalties: JSON
    $away_penalties: JSON
  ) {
    updateGame(
      id: $id
      live_data: $live_data
      lineup: $lineup
      home_scorers: $home_scorers
      away_scorers: $away_scorers
      home_penalties: $home_penalties
      away_penalties: $away_penalties
    ) {
      live_data
    }
  }
`;

const SQUAD_BY_TEAM = gql`
  query Squad($teamId: String, $seasonId: String) {
    squadByTeam(teamId: $teamId, seasonId: $seasonId) {
      _id
      active
      type
      shirt_number
      opta_shirt_number
      position
      player {
        _id
        opta_ID
        first_name
        last_name
        first_name_upper
        last_name_upper
        opta_first_name
        opta_last_name
        title
        nationality {
          _id
          opta_code
          opta_name
        }
      }
      team {
        name
        short_name
      }
    }
  }
`;

const STANDINGS_BY_SEASON = gql`
  query StandingsBySeason($season: String, $date: String) {
    standingsBySeason(season: $season, date: $date) {
      _id
      date
      stage
      teams {
        team {
          name
          variables
        }
        points
        matches_drawn
        matches_lost
        matches_played
        matches_won
        goal_difference
        rank
        rank_status
      }
    }
  }
`;

const GROUPS_BY_SEASON = gql`
  query GroupsBySeason($season: String!) {
    groupsBySeason(season: $season) {
      _id
      title
      competition {
        _id
        name
        colour
      }
      season {
        _id
        name
      }
      teams {
        team {
          _id
          name
          code
          variables
        }
        played
        points
        won
        lost
        drawn
      }
    }
  }
`;

const GROUPS = gql`
  query Groups {
    groups {
      _id
      title
      competition {
        _id
        name
        colour
      }
      season {
        _id
        name
      }
      teams {
        team {
          _id
          name
          code
          variables
        }
        played
        points
        won
        lost
        drawn
      }
    }
  }
`;

const GROUP_BY_ID = gql`
  query Groups($id: String!) {
    groupById(id: $id) {
      _id
      title
      competition {
        _id
        name
        colour
      }
      season {
        _id
        name
      }
      teams {
        team {
          _id
          name
          code
          variables
        }
        played
        points
        won
        lost
        drawn
      }
    }
  }
`;

const APIContext = React.createContext([{}, () => {}]);

const APIProvider = (props) => {
  const { client } = useContext(getApolloContext());

  function getCompetition(compId) {
    return client
      .query({
        query: COMPETITIONS,
      })
      .then((response) => {
        return response.data.competitions;
      })
      .catch((err) => console.error(err));
  }

  function getFixture({ fixtureId }) {
    return client
      .query({
        query: GAMES,
      })
      .then((response) => {
        return response.data.games.find((game) => game._id === fixtureId);
      })
      .catch((err) => console.error(err));
  }

  function getFixtures({ competition, date }) {
    return client
      .query({
        query: GAMES_BY_DATE,
        variables: { date: date },
        fetchPolicy: "network-only",
      })
      .then((response) => {
        return response.data.gamesByDate;
      })
      .catch((err) => console.error(err));
  }

  function updateGame({
    id,
    live_data,
    lineup,
    home_scorers,
    away_scorers,
    home_penalties,
    away_penalties,
  }) {
    return client
      .mutate({
        mutation: UPDATE_GAME,
        variables: {
          id,
          live_data,
          lineup,
          home_scorers,
          away_scorers,
          home_penalties,
          away_penalties,
        },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function getSquadPlayers({ teamId, seasonId, coach }) {
    return client
      .query({
        query: SQUAD_BY_TEAM,
        fetchPolicy: "network-only",
        variables: {
          teamId: teamId,
          seasonId: seasonId,
        },
      })
      .then((response) => {
        return response.data.squadByTeam;
      })
      .catch((err) => console.error(err));
  }
  function getSquad({ teamId, seasonId }) {
    return getSquadPlayers({ teamId: teamId, seasonId: seasonId });
  }

  function getGroupsBySeason({ seasonId }) {
    if (seasonId) {
      return client
        .query({
          query: GROUPS_BY_SEASON,
          fetchPolicy: "network-only",
          variables: {
            season: seasonId,
          },
        })
        .then((response) => {
          return response.data.groupsBySeason;
        })
        .catch((err) => console.error(err));
    }
  }

  function getGroup(groupId) {
    if (groupId) {
      return client
        .query({
          query: GROUP_BY_ID,
          fetchPolicy: "network-only",
          variables: {
            id: groupId,
          },
        })
        .then((response) => {
          return response.data.groupById;
        })
        .catch((err) => console.error(err));
    } else {
      return client
        .query({
          query: GROUPS,
        })
        .then((response) => {
          return response.data.groups;
        })
        .catch((err) => console.error(err));
    }
  }

  function updateKit({ id, kit, team }) {
    return client
      .mutate({
        mutation: team === "home" ? UPDATE_HOME_KIT : UPDATE_AWAY_KIT,
        variables: { id, kit },
      })
      .then((response) => {
        return response.data.updateGame;
      })
      .catch((err) => console.error(err));
  }

  function getStandings({ season, date }) {
    return client
      .query({
        query: STANDINGS_BY_SEASON,
        fetchPolicy: "network-only",
        variables: {
          season,
          date,
        },
      })
      .then((response) => {
        return response.data.standingsBySeason;
      })
      .catch((err) => console.error(err));
  }

  return (
    <APIContext.Provider
      value={{
        getFixture,
        getSquad,
        getCompetition,
        getFixtures,
        updateGame,
        getGroupsBySeason,
        getGroup,
        updateKit,
        getStandings,
      }}
    >
      {props.children}
    </APIContext.Provider>
  );
};
export { APIContext, APIProvider };
