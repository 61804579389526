import React, { useState, useContext, useEffect } from "react";
import styled from "styled-components";
import {
  GiAmericanFootballBall,
  GiRugbyConversion,
  GiSoccerBall,
} from "react-icons/gi";
import { IoIosGitCompare, IoIosArrowUp, IoIosArrowDown } from "react-icons/io";

import moment from "moment";
import { ScrollBar } from "../../Theme/Hyper";
import { APIContext } from "../../contexts/APIContext";

const Main = styled(ScrollBar)`
  display: flex;
  width: 40%;
  flex-direction: column;
  overflow-y: auto;
  height: 100%;
  font-size: 0.8em;
`;
const Team = styled.div`
  /* height: 90%; */
`;
const Row = styled.div`
  display: flex;
  width: 100%;
  min-height: ${(props) => (!props.noHover ? "25px" : "")};
  padding-right: 0.5em;
  font-size: 0.9em;
  font-weight: 100;
  align-items: center;
  box-sizing: border-box;
  opacity: ${(props) => (props.sub ? "0.5" : "1")};
  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
  border-bottom: 2px solid #181d25;
  cursor: ${(props) => (props.clickable ? "pointer" : "")};
  :hover {
    ${(props) => !props.noHover && !props.selected && { color: "#d11e42" }}
  }
`;
const ShirtNumber = styled.div`
  display: flex;
  justify-content: center;
  min-width: 40px;
  /* border-right: 1px solid #d6d6d6; */
  padding-left: 0.5em;
`;

const Name = styled.div`
  /* min-width: 300px; */
  display: flex;
  padding: 0.5em;
  width: 100%;
  color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#fff" : "#fff"};

  background-color: ${(props) =>
    props.fade ? "#181d25" : props.selected ? "#db0a41" : "#202731"};
  :hover {
    color: ${(props) =>
      props.fade ? "#181d25" : props.selected ? "#fff" : "#d11e42"};
  }
`;
const FirstName = styled.div`
  display: flex;
  /* min-width: 200px; */
  /* border-right: 1px solid #d6d6d6; */
  padding-left: 0.5em;
`;
const LastName = styled.div`
  font-weight: 500;
  display: flex;
  /* min-width: 200px; */
  /* border-right: 1px solid #d6d6d6; */
  padding-left: ${(props) => (props.padLeft ? "0.5em" : "")};
`;
const Position = styled.div`
  display: flex;
  min-width: 150px;
  padding-left: 0.5em;
`;
const Sub = styled.div`
  display: flex;
  justify-content: center;
  min-width: 27px;
`;
const Captain = styled.div`
  display: flex;
  justify-content: center;
  min-width: 27px;
`;
const Checkbox = styled.input`
  width: 20px;
  height: 20px;
`;
const Right = styled.div`
  margin-left: auto;
  display: flex;
  justify-content: space-evenly;
`;
const YellowCard = styled.div`
  width: 18px;
  height: 90%;
  background-color: #fffe00;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;
const RedCard = styled.div`
  width: 18px;
  height: 90%;
  background-color: #ff0034;
  border-radius: 2px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.1);
`;

const Cards = styled.div`
  /* margin-left: auto; */
  display: flex;
`;

const Card = styled.div`
  margin-right: 10px;
  width: 21px;
  height: 21px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 2px;
  opacity: ${(props) => (props.selected ? "1" : "0.1")};
`;

const Goals = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-left: auto;
  margin-right: 10px;
`;
const Goal = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  color: ${(props) => (props.red ? "red" : "white")};
  border-radius: 2px;
  padding: 2px;
  box-sizing: border-box;
  height: 21px;
  white-space: nowrap;
  :hover {
    background-color: #d11e42;
  }
`;
const SubIcon = styled.div`
  display: flex;
  align-items: center;
  margin-left: 0.5em;
  color: ${(props) => (props.on ? "#0adb93" : props.off ? "#db0a41" : "white")};
  border-radius: 2px;
  padding: 2px;
  box-sizing: border-box;
  height: 21px;
  opacity: ${(props) => (props.on ? "1" : props.off ? "1" : " 0.2")};
  min-width: 30px;
  justify-content: flex-end;
  :hover {
    /* background-color: #d11e42; */

    opacity: 1;
  }
`;
const FootballIcon = styled(GiSoccerBall)`
  margin-right: 2px;
`;
const ConversionIcon = styled(GiRugbyConversion)`
  margin-right: 2px;
`;
const BeenSubbed = styled.div`
  display: flex;
  justify-content: center;
  min-width: 20px;
`;
const Off = styled(IoIosArrowDown)`
  color: #db0a41;
`;
const On = styled(IoIosArrowUp)`
  color: #0adb93;
`;
const Button = styled.div`
  display: flex;
  align-items: center;
  background-color: #202731;
  box-sizing: border-box;
  border-radius: 4px;
  margin: 0.5em;
  margin-bottom: 1em;

  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex-grow: 1;
  padding: 0.5em;
  :hover {
    background-color: #db0a41;
  }
`;
const Popup = styled.div`
  background-color: ${(props) => props.theme.panelColour};
  border-radius: 4px;
  width: 200px;
  box-shadow: 0px 4px 12px -20px rgba(0, 0, 0, 0.2),
    0px 8px 20px 2px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);
  font-size: 0.8em;
  overflow: hidden;
`;

const PopupItem = styled.div`
  padding: 0.5em;
  :hover {
    background-color: #d11e42;
  }
  /* border-bottom: 1px solid grey; */
`;
const PopupGroup = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid black;
`;

export default function EFLLineup({
  team,
  players,
  lineup,
  scorers,
  selectPlayer,
  selectedPlayer,
  selectSubPlayer,
  selectedSubPlayer,
  game,
  updateGoal,
  removeGoal,
  setTeamManager,
  cards,
  subs,
  goals,
}) {
  const { getSquad } = useContext(APIContext);
  const [manager, setManager] = useState();

  useEffect(() => {
    getSquad({ teamId: team?._id, seasonId: game.season?._id }).then((data) => {
      setManager(data.find((s) => s.type === "coach" && s?.active === true));
      setTeamManager(
        data.find((s) => s.type === "coach" && s?.active === true)
      );
    });
  }, [team]);

  let team_type = team._id === game.home_team._id ? "home" : "away";
  let number_of_players_on_pitch =
    game?.competition?.competition_type === "beach" ? 5 : 11;
  let number_of_players_in_squad =
    game?.competition?.competition_type === "beach" ? 12 : 16;
  return (
    <Main>
      <Team>
        {new Array(
          lineup?.length < number_of_players_in_squad
            ? number_of_players_in_squad
            : lineup?.length
        )
          .fill(1)
          .map((item, index) => {
            let l = lineup?.find((l) => l.order === index + 1);

            let player = {
              _id: l?.squad?._id,
              ...l?.squad?.player,
              order: l?.order,
              shirt_number:
                l?.squad?.shirt_number || l?.squad?.opta_shirt_number,
              squad: l?.squad,
              yellow_card: l?.yellow_card,
              dbl_yellow_card: l?.dbl_yellow_card,
              red_card: l?.red_card,
              sub_order: l?.sub_order,
              subbed: l?.subbed,
              captain: l?.captain,
            };

            return (
              <>
                {index === number_of_players_on_pitch && (
                  <Row fade noHover>
                    <ShirtNumber></ShirtNumber>
                    <Name fade>
                      <FirstName></FirstName>
                    </Name>
                  </Row>
                )}
                <Row
                  sub={
                    !player?.squad || player?.order > number_of_players_on_pitch
                  }
                >
                  <ShirtNumber>{player?.shirt_number}</ShirtNumber>

                  <BeenSubbed>
                    {subs?.findLastIndex(
                      (sub) =>
                        sub.playerId === player.opta_ID && sub.typeId === 19
                    ) >
                      subs?.findLastIndex(
                        (sub) =>
                          sub.playerId === player.opta_ID && sub.typeId === 18
                      ) && <On />}
                    {subs?.findLastIndex(
                      (sub) =>
                        sub.playerId === player.opta_ID && sub.typeId === 19
                    ) <
                      subs?.findLastIndex(
                        (sub) =>
                          sub.playerId === player.opta_ID && sub.typeId === 18
                      ) && <Off />}
                  </BeenSubbed>
                  <Name
                    selected={
                      (selectedPlayer?._id &&
                        selectedPlayer?._id === player._id) ||
                      (selectedSubPlayer?._id &&
                        selectedSubPlayer?._id === player._id)
                    }
                  >
                    <FirstName>
                      {player?.first_name ?? player?.opta_first_name}
                    </FirstName>
                    <LastName
                      padLeft={player?.first_name ?? player?.opta_first_name}
                    >
                      {player?.last_name ?? player?.opta_last_name}{" "}
                      {player && player.captain && "(C)"}
                      <span
                        style={{
                          fontSize: ".7em",
                          fontWeight: "100",
                          alignSelf: "flex-end",
                          marginLeft: ".5em",
                        }}
                      >
                        {player &&
                          player.nationality &&
                          player.nationality.code}
                      </span>
                    </LastName>
                  </Name>
                  <Goals>
                    {goals
                      ?.filter((g) => {
                        return (
                          (g?.player || g?.playerId) &&
                          (g?.player === player?.squad?._id ||
                            g?.playerId === player?.squad?.player?.opta_ID)
                        );
                      })
                      ?.map((goal) => {
                        goal = { ...goal };
                        let time = goal.time
                          ? moment.duration(goal.time)
                          : moment.duration({
                              minutes: goal?.timeMin,
                              seconds: goal?.timeSec,
                            });
                        goal.period = goal.period ?? goal.periodId;
                        let minutes = time.minutes() + time.hours() * 60;
                        let goalTime = "";
                        // minutes += 1;
                        if (
                          goal.period === 2 &&
                          minutes > (game?.config?.half_duration || 45) * 2
                        ) {
                          goalTime =
                            (game?.config?.half_duration || 45) * 2 +
                            "+" +
                            (minutes - (game?.config?.half_duration || 45) * 2);
                        } else if (
                          goal.period === 1 &&
                          minutes > (game?.config?.half_duration || 45)
                        ) {
                          goalTime =
                            "45+" +
                            (minutes - (game?.config?.half_duration || 45));
                        } else {
                          goalTime = minutes + 1;
                        }
                        return (
                          <Goal
                            draggable="true"
                            onDragStart={() => {
                              window.goalDrag = { ...goal, team: team_type };
                            }}
                            red={
                              goal.own_goal ||
                              goal.qualifier?.find(
                                (q) => q.qualifierId === 28
                              ) !== undefined
                            }
                          >
                            <FootballIcon />
                            {goalTime}{" "}
                            {(goal.penalty ||
                              goal.qualifier?.find(
                                (q) => q.qualifierId === 9
                              ) !== undefined) &&
                              "P"}
                          </Goal>
                        );
                      })}
                  </Goals>
                  {player?.squad && (
                    <Cards>
                      <Card
                        selected={
                          cards?.find(
                            (card) =>
                              card.playerId === player.opta_ID &&
                              card.qualifier.find(
                                (q) => q.qualifierId === 31
                              ) !== undefined
                          ) !== undefined
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <YellowCard />
                      </Card>
                      <Card
                        selected={
                          cards?.find(
                            (card) =>
                              card.playerId === player.opta_ID &&
                              card.qualifier.find(
                                (q) => q.qualifierId === 32
                              ) !== undefined
                          ) !== undefined
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <YellowCard />
                      </Card>
                      <Card
                        selected={
                          cards?.find(
                            (card) =>
                              card.playerId === player.opta_ID &&
                              card.qualifier.find(
                                (q) => q.qualifierId === 33
                              ) !== undefined
                          ) !== undefined
                        }
                        onClick={(e) => {
                          e.stopPropagation();
                        }}
                        onContextMenu={(e) => {
                          e.preventDefault();
                        }}
                      >
                        <RedCard />
                      </Card>
                    </Cards>
                  )}
                </Row>
              </>
            );
          })}
      </Team>
      <Row fade noHover>
        <ShirtNumber></ShirtNumber>
        <Name fade>
          <FirstName></FirstName>
        </Name>
      </Row>
      {manager && (
        <Manager
          player={manager}
          selectedPlayer={selectedPlayer}
          selectPlayer={(player) => {
            selectPlayer(player);
          }}
          game={game}
          team={team}
          team_type={team_type}
          lineup={lineup}
        />
      )}
    </Main>
  );
}

function Manager({ player, selectedPlayer, selectPlayer }) {
  return (
    <Row
      clickable
      onClick={() => {
        if (!selectedPlayer || selectedPlayer._id !== player.player?._id) {
          selectPlayer({
            ...player,
            ...player?.player,
            title: player.player?.title ?? "Manager",
          });
        } else {
          selectPlayer(null);
        }
      }}
    >
      <ShirtNumber> </ShirtNumber>
      <BeenSubbed></BeenSubbed>
      <Name
        selected={selectedPlayer && selectedPlayer._id === player.player?._id}
      >
        <FirstName>
          {player.player?.first_name ?? player.player?.opta_first_name}
        </FirstName>
        <LastName
          padLeft={player.player?.first_name ?? player.player?.opta_first_name}
        >
          {player.player?.last_name ?? player.player?.opta_last_name} (
          {player.player?.title ?? "Manager"})
        </LastName>
      </Name>
      <Right></Right>
    </Row>
  );
}
